// color variables used across platform
$vc-blue-color: #008caa;
$vc-green-color: #00be6e;
$vc-orange-color: #f05c40;
$vc-black-color: #2a2a2a;
$vc-white-color: #ffffff;
$vc-gray-color: #777777;
$vc-light-gray-color: #d6d6d6;
$vc-light-color: #fefdf7;
$vc-light-text-color: #ebeae5;
$vc-light-blue-color: #e5f3f6;
$vc-container-background-color: #fefefc;
$vc-error-color: #ff0000;

// psuedo color variables used across platform
$vc-psuedo-blue-color: #007896;
$vc-psuedo-gray-color: #636363;
$vc-psuedo-light-gray-color: #c2c2c2;
