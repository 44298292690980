@import 'mixins/angular-material';
@import 'mixins/typography.mixin.scss';
@import 'themes/vc-color-palette.scss';

body.widescreen {
    display: flex;
    justify-content: center;
    background-color: #fefefb;
    min-width: 1280px;
    overflow: hidden;

    app-root {
        width: 1280px;
        background-color: white;
    }
}

input::-webkit-input-placeholder {
    color: #d6d6d6 !important;
}

// customizations for the carousel next/prev buttons used in facebook post creation action
.post-link-capture {
    .mat-icon-button:first-of-type {
        left: -8px !important;
        border-bottom-right-radius: 120px;
        border-top-right-radius: 120px;
        background-color: #eaeaea;
        height: 70px;
        line-height: 70px;
    }

    .mat-icon-button:last-of-type {
        right: -8px !important;
        border-bottom-left-radius: 120px;
        border-top-left-radius: 120px;
        background-color: #eaeaea;
        height: 70px;
        line-height: 70px;
    }
}

.create-outreach-circle-modal {
    @include vc-mat-modal();
}

.create-action-modal {
    @include vc-mat-modal();
    transform: scale(0.9);
}

.action-carousel {
    .carousel {
        height: calc(100vh - 75px);

        .carousel-list {
            height: calc(100vh - 73px);

            .carousel-slide {
                height: calc(100vh - 73px) !important;
            }
        }
    }
}

.action-form-field {
    .mat-form-field-infix {
        border: 1px solid #008caa;
        border-radius: 6px;
        padding: 13px;
        background-color: white;
    }

    .mat-form-field-underline {
        height: 0px !important;
    }
}

.pac-container {
    width: 525px !important;
    margin-top: 0.5%;

    .pac-item {
        padding: 4px 12px 2px;

        .pac-icon-marker {
            background-position: -20px -161px;
        }

        .pac-item-query {
            font-family: Nunito;
        }
    }
}

.center-text > .mat-simple-snackbar {
    justify-content: center;
}

/* QUOTES */
q {
    quotes: '‘' '’';
}

q:before {
    content: open-quote;
}

q:after {
    content: close-quote;
}

/* QUOTES */

/* About outreach circle dialog container */
.about-dialog-wrapper {
    mat-dialog-container {
        padding: 0 !important;
    }
}

/* About outreach circle dialog container */

/* Croppie */
.croppie-container {
    width: 100%;
    height: 100%;
}

.croppie-container .cr-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    max-height: none;
    max-width: none;
}

.croppie-container .cr-boundary {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
    position: absolute;
    border: 2px solid #fff;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.croppie-container .cr-resizer {
    z-index: 2;
    box-shadow: none;
    pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
    position: absolute;
    pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    border: 1px solid black;
    background: #fff;
    width: 10px;
    height: 10px;
    content: '';
}

.croppie-container .cr-resizer-vertical {
    bottom: -5px;
    cursor: row-resize;
    width: 100%;
    height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
    left: 50%;
    margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
    right: -5px;
    cursor: col-resize;
    width: 10px;
    height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
    top: 50%;
    margin-top: -5px;
}

.croppie-container .cr-original-image {
    display: none;
}

.croppie-container .cr-vp-circle {
    border-radius: 50%;
}

.croppie-container .cr-overlay {
    z-index: 1;
    position: absolute;
    cursor: move;
    touch-action: none;
}

.croppie-container .cr-slider-wrap {
    padding: 15px;
    text-align: center;
    background-color: white;
}

.croppie-result {
    position: relative;
    overflow: hidden;
}

.croppie-result img {
    position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/

.cr-slider {
    -webkit-appearance: none;
    /*removes default webkit styles*/
    /*border: 1px solid white; */
    /*fix for FF unable to apply focus style bug */
    width: 300px;
    /*required for proper track sizing in FF*/
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: -6px;
}

.cr-slider:focus {
    outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/

.cr-slider::-moz-range-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

.cr-slider::-ms-track {
    width: 100%;
    height: 5px;
    background: transparent;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    border-color: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-width: 6px 0;
    color: transparent;
    /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.cr-slider::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
}

/*******************************************/

/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;
}

.cr-rotate-controls button {
    border: 0;
    background: none;
}

.cr-rotate-controls i:before {
    display: inline-block;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
}

.cr-rotate-l i:before {
    content: '↺';
}

.cr-rotate-r i:before {
    content: '↻';
}

/* text copied message tooltip. */

.copy-btn-tooltip {
    background: black !important;
    border-radius: 2px !important;
    padding: 10px !important;
    color: #fff !important;

    &::after {
        display: none !important;
    }
}

.tinymce-no-border-custom-toolbar {
    .tox {
        &.tox-tinymce {
            border: 0px;
            border-radius: 5px;
        }

        .tox-tbtn {
            padding: 10px !important;
        }

        .tox-toolbar {
            background: none;
        }

        .tox-toolbar__group {
            border: none !important;

            &::after {
                content: ' ';
                height: 60%;
                width: 1px;
                background: #ccc;
            }
        }
    }
}

// custom styles for the action description editor during create action wizard
.action-description-editor {
    .tox {
        &.tox-tinymce {
            border: 0px;
            border-radius: 5px;
        }

        .tox-toolbar__group {
            justify-content: space-between;
            width: 100px;

            .tox-tbtn {
                margin-left: 5px;
                width: 28px;
                height: 28px;
            }
        }
    }
}

// custom styles to target the mat-slider-toggle that is used in the preview step of the create action flow
.create-facebook-action-notifiy-supporters {
    font-size: 13px;
}

.dialog-panel {
    @include vc-mat-modal();
}

.tooltip {
    z-index: 20;
}

.tiny-tooltip {
    @include vc-typography($font-family: Nunito, $color: #fff, $font-size: 10px);
    background: #616161 !important;
    box-shadow: 10px 20px 34px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px !important;
    padding: 8px !important;
    height: 30px !important;

    &::after {
        display: none !important;
    }
}

.action-status-help-tooltip {
    @include vc-typography($font-family: Nunito, $color: #fff, $font-size: 10px);
    background: #616161 !important;
    box-shadow: 10px 20px 34px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px !important;
    padding: 8px !important;
    text-align: left !important;

    &::after {
        display: none !important;
    }
}

.not-visible {
    visibility: hidden;
}

.preview-import-modal {
    @include vc-mat-modal();
}

.search-voters-modal {
    @include vc-mat-modal();
}

// input type=range thumb styling
input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 4px solid #008caa;
    height: 21px;
    width: 21px;
    border-radius: 28px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 4px solid #008caa;
    height: 21px;
    width: 21px;
    border-radius: 28px;
    background: white;
    cursor: pointer;
}

input[type='range']::-ms-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 4px solid #008caa;
    height: 21px;
    width: 21px;
    border-radius: 28px;
    background: white;
    cursor: pointer;
    margin-top: 1px;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background-color: #d8d8d8;
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type='range']::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background-color: #d8d8d8;
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type='range']::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type='range']::-ms-fill-lower {
    background: #d8d8d8;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type='range']::-ms-fill-upper {
    background: #d8d8d8;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

.anchor-container {
    a {
        text-decoration: underline;
    }
}

// preview button in about page
.about-page-preview-btn {
    .mat-button-wrapper {
        padding: 0 !important;
    }
}

.mat-hint {
    font-size: 15px;
    color: #777777;
    font-family: Nunito;
}

// styling for tabs in share with friends
.message-friends-container {
    .mat-tab-header {
        border: 0 !important;

        .mat-tab-label {
            height: 38px !important;
            border: 1px solid #008caa !important;
            border-bottom: 0 !important;
            opacity: 1 !important;
        }

        .mat-tab-label-active {
            background-color: #008caa !important;
            color: #fff !important;
        }

        .mat-ink-bar {
            z-index: -9;
            height: 0;
        }
    }
}

// NGP lists import
.ngp-lists {
    .mat-content {
        display: grid !important;
        grid-template-columns: 1fr 150px;

        .title-container {
            display: flex;

            mat-icon {
                font-size: 18px;
                height: 16px;
                margin-left: 5px;
                color: $vc-green-color;
            }
        }
    }
}

.ngp-list-action-panel {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;

    .import-date {
        font-size: 14px;
        font-family: Nunito-SemiBold;
        color: $vc-gray-color;
        display: flex;
        align-items: center;
    }

    .import-ngp-list-btn {
        @include vc-mat-button($padding: 30px, $height: 36px);
        width: 100px;
        display: flex;
        justify-content: center;
    }
}

.mat-menu-panel .mat-menu-content button.mat-menu-item.delete-data {
    color: $vc-gray-color !important;
}

.inactive-oc-tooltip {
    background-color: #f05c40;
    color: #fff;

    &::after {
        bottom: 100%;
        left: 6%;
        border-width: 10px;
        border-color: transparent transparent #f05c40 transparent;
    }
}

.checklist-tooltip {
    background-color: $vc-blue-color !important;
    border-radius: 3px !important;
    max-width: 250px;

    &::after {
        bottom: 100%;
        right: 35px;
        border-width: 10px;
        border-color: transparent transparent $vc-blue-color transparent;
    }
}

.email-editor-wrapper .editor-body-wrapper {
    .mat-checkbox-frame {
        border-color: #fff !important;
    }

    .mat-checkbox-checked .mat-checkbox-background {
        background-color: #fff !important;

        .mat-checkbox-checkmark-path {
            stroke: #000 !important;
        }
    }

    .edit-template-body {
        .tox-tinymce {
            border: 0 !important;
        }
    }
}

.recruitment-link-dialog-wrapper .recruitment-editor-body {
    .tox-tinymce {
        border: 0 !important;
    }
}

.char-count-container {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;

    span {
        font-family: Nunito-Bold !important;
        color: #2a2a2a !important;
    }

    .error {
        color: red !important;
    }
}

.tox-statusbar__resize-handle {
    display: none !important;
}

// styling for website begins

.show-desktop {
    .container {
        width: 1280px;
        margin: auto;
    }

    .container-wide {
        width: 80%;
    }

    .container-medium {
        width: 60%;
    }

    .container-small {
        width: 40%;
    }

    .container-tiny {
        width: 30%;
    }

    .container-split {
        width: 50%;
    }

    .visible-small,
    .visible-medium {
        display: none !important;
    }
}

.show-tablet {
    .container {
        // width: 1280px;
        margin: auto;
    }

    .container-wide {
        width: 80%;
    }

    .container-medium {
        width: 60%;
    }

    .container-small {
        width: 40%;
    }

    .container-tiny {
        width: 30%;
    }

    .container-split {
        width: 50%;
    }

    .visible-large {
        display: none !important;
    }
}

.show-mobile {
    .s-stretch,
    .container-wide,
    .container-medium,
    .container-small,
    .container-tiny,
    .container-split {
        width: 100%;
    }

    .visible-large {
        display: none !important;
    }

    .s-text-center {
        text-align: center;
    }

    .s-center {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.show-on-scroll {
    .animated {
        display: none !important;
    }
}

.support-widget .form-wrapper .message-box {
    .mat-form-field-infix {
        padding: 0 !important;
    }

    .mat-form-field-underline {
        height: 0 !important;
        background-color: #fff;
    }
}

// styling for website ends
.cursor-pointer {
    cursor: pointer;
}

.common-filter-empty-placeholder {
    @include h3($font-size: 16px, $color: $vc-black-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 230px;
    min-width: 420px;
    padding: 20px 0;

    img {
        width: 96px;
        margin-bottom: 40px;
    }

    .icon {
        margin-bottom: 20px;
        color: #d6d6dd;
        font-size: 80px;
    }

    .label {
        padding: 0 20px;
        margin-bottom: 20px;
        line-height: 20px;
        text-align: center;
        color: #777;

        &.text-black {
            color: $vc-black-color;
        }
    }
}
