// Variables
// --------------------------
$zmdi-font-name: 'Material-Design-Iconic-Font' !default;
$zmdi-font-path: '/assets/fonts' !default;
$zmdi-css-prefix: zmdi-hc !default;
$zmdi-icon-prefix: zmdi !default;
$zmdi-version: '2.2.0' !default;
$zmdi-border-color: #9e9e9e !default;
$zmdi-inverse: #ffffff !default;
$zmdi-li-width: (30em / 14) !default;
// Legacy code for backward capability with 2.0.x version
$md-font-path: '/fonts' !default;
$md-css-prefix: zmd !default;
$md-icon-prefix: zmdi !default;
$md-version: '2.2.0' !default;
$md-border-color: #9e9e9e !default;
$md-inverse: #ffffff !default;
$md-li-width: (30em / 14) !default;
