// Pulled icons
// -------------------------

.#{$zmdi-icon-prefix} {
    &.pull-left {
        float: left;
        margin-right: 0.15em;
    }
    &.pull-right {
        float: right;
        margin-left: 0.15em;
    }
}
