/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Lets import our font files
@import './fonts/fonts.scss';

// import themes
@import './themes/themes';

/* Vendor styles*/
@import 'vendor/vendor';

// Color palette
@import 'themes/vc-color-palette';

// The mixin imports
@import './mixins/angular-material';

// global default mixin includes
@import './styles-global';

// global style customizations
@import './styles-customizations';

@import '~leaflet/dist/leaflet.css';
