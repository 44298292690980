@import './typography.mixin';

// Customized material input
@mixin vc-mat-input-form-field {
    mat-form-field,
    .mat-form-field-infix {
        width: 100% !important;
    }

    .mat-form-field-label-wrapper {
        @include vc-typography(16px, Nunito-Light, $vc-gray-color);
        text-transform: uppercase;
    }

    input.mat-input-element {
        @include vc-typography(18px, Nunito, $vc-black-color);
        width: 98% !important;
    }

    .close-icon {
        font-size: 17px;
    }

    .mat-error {
        @include vc-typography(12px, Nunito-SemiBold, red);
    }

    mat-form-field.mat-focused {
        mat-hint {
            display: block;
        }
    }

    mat-label {
        @include vc-typography(16px, Nunito-Regular, $vc-gray-color);
        text-transform: uppercase;
    }

    .mat-select {
        @include vc-typography(16px, Nunito-Semibold !important, $vc-black-color);
    }
}

.mat-form-field-underline {
    background-color: $vc-gray-color;
}

.mat-autocomplete-panel,
.mat-select-panel {
    // max-height: 450px !important;

    .mat-option {
        @include vc-typography(16px, Nunito-Semibold, $vc-black-color);
    }
}

// Customized material button
@mixin vc-mat-button(
    $height: 40px,
    $font-size: 16px,
    $font-family: Nunito-SemiBold,
    $border-radius: 2px,
    $padding: 10px,
    $color: white,
    $disable-line-height: false
) {
    padding-left: $padding;
    padding-right: $padding;
    height: $height;
    @include vc-typography($font-size, $font-family, $color);
    border-radius: $border-radius;
    -webkit-font-smoothing: antialiased;

    @if $disable-line-height==false {
        line-height: $height;
    }
}

@mixin vc-mat-full-width-button(
    $height: 40px,
    $font-size: 16px,
    $font-family: Nunito-SemiBold,
    $border-radius: 2px,
    $color: white
) {
    width: 100%;
    height: $height;
    line-height: $height;
    @include vc-typography($font-size, $font-family, $color);
    border-radius: $border-radius;
    -webkit-font-smoothing: antialiased;
    min-width: 62px;
}

@mixin vc-mat-menu($height: 40px) {
    .mat-menu-panel {
        .mat-menu-content {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .mat-menu-item {
                border-bottom: solid 1px $vc-light-gray-color;
                height: $height;
                line-height: $height;
                color: $vc-black-color;
                font-family: Nunito-Bold;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

@mixin vc-mat-modal($padding: 0) {
    max-height: 100vh;

    .mat-dialog-container {
        padding: $padding;
    }
}
