// Nunito family
@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('/assets/fonts/nunito/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: 'Nunito-Light';
    src: url('/assets/fonts/nunito/Nunito-Light.ttf');
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('/assets/fonts/nunito/Nunito-Regular.ttf');
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-Regular.ttf');
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('/assets/fonts/nunito/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('/assets/fonts/nunito/Nunito-Bold.ttf');
}

@font-face {
    font-family: 'Nunito-Black';
    src: url('/assets/fonts/nunito/Nunito-Black.ttf');
}

@font-face {
    font-family: 'Nunito-Italic';
    src: url('/assets/fonts/nunito/Nunito-Italic.ttf');
}

@font-face {
    font-family: 'Nunito-SemiboldItalic';
    src: url('/assets/fonts/nunito/Nunito-SemiBoldItalic.ttf');
}
