@import './themes/vc-color-palette.scss';

mat-spinner#signup-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#training-invite-spinner {
    circle {
        stroke: #2a2a2a;
    }
}

mat-spinner#create-outreach-circle-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#update-outreach-circle-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#activate-action-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#upload-outreach-logo-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#remove-outreach-logo-spinner {
    circle {
        stroke: #333333;
    }
}

mat-spinner#contact-us-logo-spinner {
    circle {
        stroke: $vc-blue-color;
    }
}

mat-spinner#send-report-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#import-ngp-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#admin-invite-spinner {
    circle {
        stroke: #ffffff;
    }
}

mat-spinner#help-panel-training-invite {
    circle {
        stroke: #333333;
    }
}

mat-spinner.white-spinner,
mat-spinner.update-share-button-spinner {
    circle {
        stroke: #ffffff !important;
    }
}

@include vc-mat-menu();

.outreachcircle-visibility.private {
    .card-header {
        background-color: #2a2a2a !important;

        span,
        a {
            color: #ffffff !important;
        }
    }
}

mat-spinner.edit-action-form-spinner {
    circle {
        stroke: #ffffff;
    }
}

.edit-action-form,
.edit-donation-form {
    input {
        color: #2a2a2a;
    }
}

.edit-attendance-container {
    .personalize-button-container {
        input {
            color: #2a2a2a;
        }
    }
}

mat-spinner.edit-attendance-checkin-spinner {
    circle {
        stroke: #ffffff;
    }
}

.links-codes-tabs {
    .mat-tab-header {
        border: 0 !important;

        .mat-tab-labels {
            display: grid !important;
            grid-template-columns: 1fr 1fr;

            .mat-tab-label {
                background-color: #dcdcdc;
                border-radius: 20px 20px 0 0;
                font-family: Nunito-Bold;
                font-size: 20px;

                &.mat-tab-label-active {
                    background-color: #ffffff;
                    color: #008caa;
                }
            }
        }

        .mat-ink-bar {
            display: none !important;
        }
    }

    .mat-tab-body-wrapper {
        top: -3px;
    }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #008caa !important;
}

.select-outreachcircle.mat-autocomplete-panel .mat-option {
    border-top: 1px solid #008caa !important;
}
.mat-ink-bar {
    display: none !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}
table {
    .mat-header-row {
        background: #008caa;
    }

    .mat-header-cell {
        color: white;
        text-transform: uppercase;
    }
    border-collapse: inherit;
    tr.mat-header-row {
        height: 45px;
    }
}
.mat-table {
    border: 1px solid #008caa;
}
.mat-option {
    height: auto !important;
}

.admin-details-menu {
    border-radius: 3px;

    .oc-owner {
        color: #008caa;
        font-family: 'Nunito-Bold';
    }

    .admin-email {
        padding: 1px 12px;
        display: flex;
        min-width: 250px;

        p {
            @include vc-typography(
                $font-family: 'Nunito-SemiBold',
                $color: #2a2a2a,
                $font-size: 14px
            );
            line-height: 20px;
            margin: 3%;
        }
    }
}

.admin-details-menu > div:not(:last-of-type) {
    border-bottom: 1.5px solid lightgray;
}

.oc-list-user-table {
    background: #2a2a2a;
    width: 218px;
    border-radius: 3px;
    padding: 1px 12px;

    .title {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
    }
    .oc-name {
        color: #ffffff;
        font-family: Nunito;
        font-size: 14px;
        line-height: 20px;
    }
}

app-pricing-and-status {
    .mat-select-value {
        color: #008caa;
        font-family: Nunito;
        font-size: 16px;
        font-weight: bold;
    }
}

ngx-mat-drp {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 14px 10px 10px 10px;
    }
    .mat-form-field-infix {
        padding: 0;
        border-top: 0;
    }
}

.mat-pseudo-checkbox-checked {
    background: #008caa !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
}

.admin-email-menu {
    .copy-all p {
        text-decoration: underline;
        color: #008caa;
    }
    mat-icon {
        margin-left: 10px;
        font-size: 15px;
        margin-top: 4px;
        color: #008caa;
    }
    p {
        color: #2a2a2a;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        display: flex;
        margin: 0;
        word-break: break-word;
    }
    div {
        padding: 10px 15px;
        display: flex;
        border: 1px solid #d8d8d8;
        p:last-of-type {
            align-items: center;
        }
    }
}

app-supporter-details {
    .mat-slide-toggle .mat-slide-toggle-ripple {
        height: 0;
        width: 0;
    }
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
        background-color: #2ac0e2;
    }
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
        background-color: #008caa;
    }
    .mat-slide-toggle-content {
        font-family: Nunito-SemiBold;
        font-size: 14px;
    }
}

app-outreachcircle {
    .navBar {
        background: #269db7;
        margin-left: 1%;
        .mat-tab-label-active {
            background: rgba(45, 45, 45, 0.3);
        }
        .mat-tab-label {
            color: white;
            opacity: 1;
            font-size: 14px;
        }
    }
}

.reduce-width-and-font {
    max-width: 180px;
    font-size: 12px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: white;
}

.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
    line-height: 30px;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
    /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
    -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
    -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    .name {
        font-family: Nunito-Bold;
        font-size: 14px;
        text-align: center;
    }
    p {
        margin: 0;
        color: white;
        word-break: break-word;
        margin-bottom: 5px;
    }
    .supporter-count {
        width: 50%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #025f73;
        border-radius: 20px;
        background-color: #133b44;
        margin: 5%;
        color: white;
        font-size: 16px;
    }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background-color: rgba(24, 25, 25, 0.9);
}

.leaflet-popup-content-wrapper {
    border-radius: 5px;
}

.table-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    height: calc(100% - 45px);
}

.mat-progress-spinner circle {
    stroke: #008caa;
}

.archive-outreach-circle-dialog-container {
    margin: -24px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 10px;
        background: $vc-orange-color;
        .title {
            @include h3($font-family: Nunito-Bold, $font-size: 18px, $color: #fff);
            text-align: center;
            line-height: 24px;
        }
    }

    .body {
        padding: 15px 30px;
        line-height: 22px;

        .confirmation-icon {
            text-align: center;
            .icon {
                width: 40px;
            }
        }

        .confirmation-message {
            @include h3($font-family: Nunito-SemiBold, $font-size: 16px, $color: $vc-black-color);
            text-align: center;
            border-bottom: 1px solid $vc-light-gray-color;
            padding: 15px 0;
        }

        .btn-set {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
            padding: 15px 0;

            .cancel {
                border-color: $vc-orange-color;
            }

            .confirm {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }
    }
}
