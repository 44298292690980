// Icon sizes
// -------------------------

.#{$zmdi-css-prefix}-lg {
    font-size: (4em / 3);
    line-height: (3em / 4);
    vertical-align: -15%;
}
.#{$zmdi-css-prefix}-2x {
    font-size: 2em;
}
.#{$zmdi-css-prefix}-3x {
    font-size: 3em;
}
.#{$zmdi-css-prefix}-4x {
    font-size: 4em;
}
.#{$zmdi-css-prefix}-5x {
    font-size: 5em;
}
