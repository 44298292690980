@import '../themes/vc-color-palette';

@mixin vc-typography($font-size, $font-family, $color) {
    font-size: $font-size;
    font-family: $font-family;
    color: $color;
}

@mixin h1($font-size: 36px, $font-family: Nunito-Semibold, $color: #2a2a2a) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin h2($font-size: 28px, $font-family: Nunito-SemiBold, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin h3($font-size: 24px, $font-family: Nunito-SemiBold, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin para1($font-size: 20px, $font-family: Nunito-Regular, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin para2($font-size: 18px, $font-family: Nunito-Regular, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin para3($font-size: 16px, $font-family: Nunito-Regular, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin link($font-size: 16px, $font-family: Nunito-Regular, $color: $vc-blue-color) {
    @include vc-typography($font-size, $font-family, $color);
    cursor: pointer;
}

@mixin help-text($font-size: 14px, $font-family: Nunito-Regular, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin help-text2($font-size: 12px, $font-family: Nunito-Regular, $color: #666666) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin error-text($font-size: 14px, $font-family: Nunito-Regular, $color: #ff0d0d) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin spaced-text(
    $font-size: 28px,
    $font-family: Nunito-SemiBold,
    $color: #666666,
    $letter-spacing: 10px
) {
    @include vc-typography($font-size, $font-family, $color);
    letter-spacing: $letter-spacing;
}

@mixin sub-title($font-size: 16px, $font-family: Nunito-Regular, $color: #2a2a2a) {
    @include vc-typography($font-size, $font-family, $color);
}

@mixin placeholder($color: #d6d6d6, $fontFamily: Nunito) {
    input::-webkit-input-placeholder {
        color: $color !important;
        font-family: $fontFamily !important;
    }

    input:-moz-placeholder {
        /* Firefox 18- */
        color: $color !important;
        font-family: $fontFamily;
    }

    input::-moz-placeholder {
        /* Firefox 19+ */
        color: $color !important;
        font-family: $fontFamily;
    }

    input:-ms-input-placeholder {
        color: $color !important;
        font-family: $fontFamily;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0px 9999px white;
    }
}
